@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
a {
  color: inherit;
}

body {
  font-family: Lato, Helvetica, Arial, sans-serif;
}
