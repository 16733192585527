@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap);
* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
a {
  color: inherit;
}

body {
  font-family: Lato, Helvetica, Arial, sans-serif;
}

.darkmode-input::-webkit-input-placeholder {
  color: #999 !important;
  opacity: 1; /* Firefox */
}

.darkmode-input:-ms-input-placeholder {
  color: #999 !important;
  opacity: 1; /* Firefox */
}

.darkmode-input::placeholder {
  color: #999 !important;
  opacity: 1; /* Firefox */
}

.darkmode-input:-ms-input-placeholder {
  color: #999 !important;
}

.darkmode-input::-ms-input-placeholder {
  color: #999 !important;
}

.dark-theme {
  background: #232429 !important;
  color: #eee !important;
}

.dark-table > th > button {
  background: #232429 !important;
  color: #eee !important;
}

.dark-table > th > button:hover {
  background: #4e505a !important;
  color: #eee !important;
}

.dark-table td > button {
  background: #232429 !important;
  color: #eee !important;
  border-left: solid 2px #999;
  border-right: solid 2px #999;
}

.dark-table td > button:hover {
  background: #4e505a !important;
  color: #eee !important;
}

.dark-table-row:hover {
  background: #4e505a !important;
  color: #eee !important;
}

.dark-login-frame {
  background: #444 !important;
}

.dark-login-inner {
  background: #2c2c2c !important;
}

.dark-datepicker button {
  background: #232429 !important;
  color: #eee !important;
  border-left: solid 2px #999;
  border-right: solid 2px #999;
}

.dark-datepicker button:hover {
  background: #4e505a !important;
  color: #eee !important;
}

